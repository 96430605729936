import Infor from "../pages/frontend/Account/Infor";
import Order from "../pages/frontend/Account/Order";

const RouterAccount = [
    {path:'/thong-tin',component:Infor},
    {path:'/quan-ly-don-hang',component:Order},
    // {path:'/dang-ky',component:Register},
];

export default RouterAccount;
